export default function Address() {
    return (
        <>
            <p>
                DC 37 — IT Professionals of NYC<br />
                125 Barclay Street<br />
                New York, NY 10007
            </p>
        </>
    );
}